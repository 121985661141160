import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { RiArrowLeftLine } from "react-icons/ri";
import { Link } from 'gatsby';
import { Button } from "react-bootstrap"
import * as style2 from '../buyerdetaipages/buyerdetail.module.css'
import AvatarImage from "../../components/Avatar/avatarImage"
import Moment from "moment-timezone";
import { useLocation } from "@reach/router"
import { manageToursdetails,claimPropertyList } from "../../components/Api/ListingApi"
import BuyerScheduleCalender from "../../components/popup/buyerScheduleCalender"
import CancelTour from "../../components/popup/CancelTour"
import chatimg from "../../../static/images/ChatImage.png"
import { useSelector } from "react-redux"
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import ChatPopup from "../../components/popup/ChatPopup"
import SkeletonHorizondalCard from "../../components/card/skeletonVerticalCard";
import { ChatData } from "../../common/GenericFunction";
import TourAvailablePopup from "../../components/popup/tourAvailablePopup"

function ManageTours() {
    const [manageData, setManageData] = useState([])
    const location = useLocation()
    const { props, check, currentPage,propertyStatus} = location.state
    // console.log("props",props)
    const [userSuccess, setUserSucess] = useState(false)
    const toggleUserSuccess = () => setUserSucess(p => !p)
    const [click, setClick] = useState(false);
    const toggleClick = () => setClick(p => !p);
    const [item, setItem] = useState({})
    const [modifyData, setModifyData] = useState({})
    const [success, setSucess] = useState(false);
    const messages = ""
    const toggleSuccess = () => setSucess(p => !p);
    const handleModifyData = (item) => {
        setModifyData(item)
        toggleClick()
    }
    const [isTourAvailable, setIsTourAvailable] = useState(false)
    const handleIsTourAvailablePopup = () => {
        setIsTourAvailable(p => !p)
    }
    const [loader, setLoader] = useState(false);
    const [chatAgent, setChatAgent] = useState({})
    const [chatUser, setChatUser] = useState({})
    const [chatId, setChatId] = useState("")
    const userDetails = useSelector(state => state.user.userDetail);
    const userlocation = useSelector(state => state.user.userLocation);
    const chattingUserData = useSelector(state => state.dashboard.chat_disconnect)
    const [openChat, setOpenChat] = useState(false)
    const [key, setKey] = useState(0)
    const showChatDetail = () => {
        if (openChat === false) {
            setLoader(true);
        }
        else {
            setLoader(false);

        }
        setOpenChat(p => !p)
    }
    const handleChat = async (id, name, lastName, image) => {
        let names = name + " " + (lastName !== null ? lastName : " ")
        await chattingUserData !== null && chattingUserData.disconnectUser()
        const apikey = process.env.STREAM_CHAT_APIKEY;

        const chatClient = StreamChat.getInstance(apikey);
        await chatClient.connectUser({ id: id, name: names, image: image }, chatClient.devToken(id));
        await chatClient.disconnectUser()

        setChatAgent({ id: id, name: names, image: image })
        setChatUser({ id: userDetails._id, name: userDetails.name, image: hanldeImage(userDetails.img) })
        setChatId(id + userDetails._id)
        showChatDetail()
    }

    useEffect(() => {
        async function fetchData() {
            tourListing()
            myList()
        } fetchData()
    }, [])
    const tourListing = async () => {
        let id = props._id
        setLoader(true)
        await manageToursdetails(id).then((res) => {
            setManageData(res.data.data)
            setKey(1)
            setLoader(false)
        })
    }

    const hanldeImage = (img) => {
        let data = img !== null && img.split('/')[0]
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        }
        else {
            return img
        }
    }

    const myList = async (status, filterPage) => {
        
        let userId = localStorage.getItem('userId')
        let getParams = {
            status: 'Approve',
            id: userId,
            pagesize: 20,
            page: filterPage,
            sort_field: "_id",
            sort_value: "-1",
            listing_status: props.listing_status
        }
        setLoader(true);
        await claimPropertyList(getParams).then((res) => {
            setLoader(false);
           
        })
    }

    // refresh={refresh} verifyData={verifyData}

    return (
        <div>
            <Layout>
                <>
                    {openChat && <ChatPopup show={openChat} toggleShow={showChatDetail} agent={chatAgent} user={chatUser} id={chatId} />}
                </>
                <CancelTour userSuccess={userSuccess} toggleUserSuccess={toggleUserSuccess} data={item} reload={tourListing} success={success} toggleSuccess={toggleSuccess} messages={messages} />
                {click && <BuyerScheduleCalender click={click} toggleClick={toggleClick} details={modifyData} detail={modifyData} propertyId={""} isBuyer={false} refresh={tourListing} />}
                {isTourAvailable && <TourAvailablePopup show={isTourAvailable} property_id={props} toggleShow={handleIsTourAvailablePopup} propertyStatus={propertyStatus} currentPage={currentPage} refresh={myList}  check={"mylist"} />}
                <div>

                    {check === "mySell" ?
                        <Link to="/sellers" style={{ textDecoration: "none" }}>
                            <div className='networkbackicon NetworkBackIcon'>
                                <RiArrowLeftLine style={{ fill: '' }} size="25px" className='NetworkArrowLine' />
                                <p className='backtext m-0 ps-1'>Back</p>
                            </div>
                        </Link> :

                        <div className='d-flex justify-content-between align-items-center mt-3'>
                            <Link to="/myList" style={{ textDecoration: "none" }}>
                                <div className="d-flex">
                                    <RiArrowLeftLine size="25px" className='NetworkArrowLine' />
                                    <p className='backtext m-0 ps-1'>Back</p>
                                </div></Link>
                            <div><button className="btn primaryColor f-14 py-2 DejaVuSansCondensed tourviewbut" onClick={handleIsTourAvailablePopup}>Tour Availability</button></div>
                        </div>
                    }
                </div>

                <div className='tourbuyer mb-5' >
                    <div>
                        {key == 0 ?

                            <SkeletonHorizondalCard purpose="tour" />

                            :
                            manageData.map((item, index) => (
                                <div className='mb-4' key={index}>

                                    <div key={index} className='row'
                                        style={{
                                            border: "1px solid lightgrey",
                                            borderRadius: "20px",
                                            boxShadow: "1px 2px 4px 2px #e4e5e7",
                                            margin: "30px 0 0 0",
                                            padding: "20px 10px",
                                            background: "#fff",
                                        }}>
                                        <div className='col-md-2 mt-3 zoom'>
                                            {item.buyer_agent_id !== null ?
                                                <div>
                                                    {item.buyer_agent_id !== null && item.buyer_agent_id.img ?
                                                        <img src={hanldeImage(item.buyer_agent_id !== null && item.buyer_agent_id.img)} className={style2.buyerImage} id={style2.buyerAvatarImage} alt='listimage' crossOrigin="anonymous" /> :
                                                        <>{item.buyer_agent_id !== null && item.buyer_agent_id.name ? <div style={{ width: '88px', height: '88px', marginLeft: "20px" }}>
                                                            <AvatarImage fontSize={'38px'} data={item.buyer_agent_id !== null && item.buyer_agent_id.name ? item.buyer_agent_id !== null && item.buyer_agent_id.name : ''} /></div>
                                                            : null}</>}  </div>

                                                : <div>
                                                    {item.user_id !== null && item.user_id.img ?
                                                        <img src={hanldeImage(item.user_id !== null && item.user_id.img)} className={style2.buyerImage} id={style2.buyerAvatarImage} alt='listimage' crossOrigin="anonymous" /> :
                                                        <>{item.user_id !== null && item.user_id.name ? <div style={{ width: '88px', height: '88px', marginLeft: "20px" }}>
                                                            <AvatarImage fontSize={'38px'} data={item.user_id !== null && item.user_id.name ? item.user_id !== null && item.user_id.name : ''} /></div>
                                                            : null}</>}  </div>}
                                        </div>
                                        <div className='col-md-2 mt-4'>
                                            {item.buyer_agent_id !== null ?
                                                <div>
                                                    <p>Buyer Agent</p>
                                                    <p className={style2.listbuyername}>{item.buyer_agent_id !== null && item.buyer_agent_id.name}</p>
                                                </div> :
                                                <div>
                                                    <p>Buyer</p>
                                                    {item.user_id !== null && item.user_id.is_private_profile === true ?
                                                        <p className={style2.listbuyername}>{item.user_id.nick_name}</p> :
                                                        <p className={style2.listbuyername}>{item.user_id !== null && item.user_id.name}</p>}
                                                </div>}
                                        </div>
                                        <div className='col-md-2 mt-4'>
                                            <p>Tour Date</p>
                                            <p className={style2.listtime}>
                                                {(Moment(item.date_time).format('DD-MM-YYYY'))}
                                            </p>
                                        </div>
                                        <div className='col-md-1 mt-4'>
                                            <p>Tour Time</p>
                                            <p className={style2.listtime}>
                                                {(Moment(item.date_time.split(":")[0] + ":" + item.date_time.split(":")[1]).format('hh:mm A'))}
                                            </p>
                                        </div>
                                        <div className='col-md-2 mt-3'>
                                            {item.agent_id.img ?
                                                <img src={hanldeImage(item.agent_id.img)} className={style2.buyerImage} id={style2.buyerAvatarImage} alt='listimage' crossOrigin="anonymous" /> :
                                                <>{item.agent_id.name ? <div style={{ width: '88px', height: '88px' }}>
                                                    <AvatarImage fontSize={'38px'} data={item.agent_id.name ? item.agent_id.name : ''} /></div>
                                                    : null}</>}
                                        </div>
                                        <div className='col-md-1 mt-4'>
                                            <p>Agent</p>
                                            <p className={style2.listagent}>{item.agent_id.name}</p>
                                        </div>
                                        <div className='col-md-2 '>
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Button className={style2.listmodifybut1} id={style2.ModifyButton} style={{ margin: "4px" }} onClick={() => { handleModifyData(item); }}>Modify</Button>
                                                    <Button className={style2.listmodifybut1} id={style2.ModifyButton} style={{ margin: "4px" }} onClick={() => { setItem(item); toggleUserSuccess() }} >Cancel</Button>
                                                </div>
                                                <img src={chatimg} alt="msgint" className="msgintmanage" onClick={() => { handleChat(item.user_id._id, item.user_id.name, item.user_id.last_name, item.user_id.img ? hanldeImage(item.user_id.img) : ""); ChatData("Manage Tour Chat", userDetails, item.user_id._id, userlocation) }} onKeyPress={() => { handleChat(item.user_id._id, item.user_id.name, item.user_id.last_name, item.user_id.img ? hanldeImage(item.user_id.img) : ""); }} aria-hidden />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))}
                    </div>
                    <div>
                        {manageData && manageData.length == 0 && <div className='MytourNodata mt-5' >There are currently no tours scheduled <br />for this property.</div>}
                    </div>
                </div>
            </Layout >
        </div >
    )
}
export default ManageTours